import React from "react";

const Task = ({note}) => {
  return (
    <div className="card task">
        <div className="top-section">
        <h1>Task</h1>
        <div className="sticker"></div>
        <span>{note.task_text}</span>
        </div>
    </div>
  );
};

export default Task;
