import React, { useEffect, useState } from "react";
import NotesList from "../components/NotesList";
import NewNote from "../components/NewNote";
import Search from "../components/Search";
import PageHeader from "../components/PageHeader";
import RetrieveTasks from "../api/RetrieveTasks";
import { useNavigate } from "react-router-dom";
import { createClient } from "@supabase/supabase-js";
import {Helmet} from 'react-helmet';

const AppDashboard = () => {
  const [notes, setNotes] = useState([]);
  const [searchText, setSearchtext] = useState("");
  const [count, setCount] = useState(0);
  const [timerStatus, setTimerStatus] = useState("stop");
  const [editTask, setEditTask] = useState("");
  const [lastId, setLastId] = useState(0)
  // const [newNote, setNewNote] = useState({})

  const supabaseUrl = "https://mpvrzftknsiwrkkttwag.supabase.co";
  const supabaseAnonKey =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im1wdnJ6ZnRrbnNpd3Jra3R0d2FnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODEzNDYyNDIsImV4cCI6MTk5NjkyMjI0Mn0.Nb6ZUFXaQ5MHMaqTT_kaiHcGwUM9OXkXeIv29qwqv2w";
  const supabase = createClient(supabaseUrl, supabaseAnonKey);

  useEffect(() => {
    async function getData() {
      const { data, error } = await supabase.from("tasks").select("*");
      if (error) {
        console.log(error);
      } else {
        setNotes(data);
        let highestId = data.reduce(function(prev, current){
          return (prev.id > current.id) ? prev : current
        })
        setLastId(highestId.id)
      }
    }

    getData();
  }, []);

  useEffect(() => {
    console.log("lastID", lastId)
  },[lastId])

  useEffect(() => {
    if (timerStatus === "start") {
      const timer = setInterval(() => {
        setCount((count) => count + 1);
      }, 100);
      return () => clearInterval(timer);
    }
  }, [timerStatus, setCount]);

  const StartCount = () => {
    if (timerStatus === "stop") {
      setTimerStatus("start");
    } else {
      setTimerStatus("stop");
    }
  };

  const addTask = (newTask) => {
    setNotes([...notes, newTask]);
    setLastId(newTask.id)
  };

  const CreateNewNote = async (text, priority, estimate, due_date) => {
    const date = new Date();
    const { data, error } = await supabase.from("tasks").insert([
      {
        id: lastId + 1,
        task_text: text,
        created_date: date,
        due_date: due_date,
        task_priority: priority,
        estimated_time: estimate * 60,
      },
    ], { returning: '*' });
    if (error) {
      console.log(error);
    } else {
      addTask(
        {
          id: lastId + 1,
          task_text: text,
          created_date: date,
          due_date: due_date,
          task_priority: priority,
          estimated_time: estimate * 60,
        });
    }
  };

  const EditTask = (id, date, priority, text) => {
    console.log("new text", text);
    const modifiedNote = notes.map((note) => {
      if (note.id === id) {
        return {
          ...note,
          due_date: date,
          task_priority: priority,
          task_text: text,
        };
      } else {
        return note;
      }
    });
    setNotes(modifiedNote);
    setEditTask("edited");
    console.log("edited note after", notes);
  };

  const HandleEditTask = async (id, date, priority, text) => {
    const updates = {
      due_date: date,
      task_priority: priority,
      task_text: text,
    };
    const filter = { id: id };
  const { data, error } = await supabase
  .from('tasks')
  .update(updates)
  .match(filter)
  // .returning('*');
  if (error) {
    console.error(error);
  } else {
    EditTask(id, date, priority, text);
    // console.log(data)
  }
  };

  const updateDate = (id, date) => {
    const yyyy = date.slice(0, 4);
    const mm = date.slice(5, 7);
    const dd = date.slice(8, 10);
    const newDay = parseInt(dd) + 1;
    const newDate = yyyy + "-" + mm + "-" + newDay.toString();
    const newNotes = notes.map((note) => {
      if (note.id === id) {
        return {
          ...note,
          due_date: newDate,
          times_pushed: note.times_pushed + 1,
        };
      } else {
        return note;
      }
    });
    setNotes(newNotes);
  };

  const addDays = async (date, id) => {
    const yyyy = date.slice(0, 4);
    const mm = date.slice(5, 7);
    const dd = date.slice(8, 10);
    const newDay = parseInt(dd) + 1;
    const newDate = yyyy + "-" + mm + "-" + newDay.toString();
    updateDate(id, date);
    try {
      const response = await RetrieveTasks.patch(`${id}/push`, {
        newDate: newDate,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const deleteNote = async (id) => {
    const { data, error } = await supabase
    .from('tasks')
    .delete()
    .match({ id });

  if (error) {
    console.error(error);
  } else {
      const newNotes = notes.filter((note) => note.id !== id);
      setNotes(newNotes);
      // setLastId(lastId - 1)
  }
  };

  const cleanDate = (date) => {
    const newDate = date.slice(0, 10);
    // console.log("cleanDate", newDate);
    return newDate;
  };

  const taskDue = (due_date) => {
    let date = new Date();
    let dd = String(date.getDate()).padStart(2, "0");
    let mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = date.getFullYear();
    let today = yyyy + "-" + mm + "-" + dd;
    if (due_date === today) {
      return "today";
    }
  };

  let navigate = useNavigate();

  const handleClickTaskPage = async (id) => {
    // event.preventDefault();
    try {
      navigate(`/todo/${id}`);
      // updateInputItems("");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
    <Helmet>
      <link rel="icon" type="image/ico" href="%PUBLIC_URL%/todo.ico" />
      <title>To-do</title>
    </Helmet>
      <div className="page-layout">
        <PageHeader />
        {/* <Timer count={count} /> */}
        <Search handleSearch={setSearchtext} />
        <div className="page-grid">
          <div className="container">
            <span className="header">New task</span>
            <NewNote CreateNewNote={CreateNewNote} />
          </div>
          <div className="container">
            <span className="header">Today</span>
            <NotesList
              notes={notes
                .sort((a, b) => a.task_priority - b.task_priority)
                .filter((note) =>
                  note.task_text
                    .toLowerCase()
                    .includes(
                      taskDue(cleanDate(note.due_date)) === "today" &&
                        !note.done &&
                        searchText
                    )
                )}
              deleteNote={deleteNote}
              addDays={addDays}
              StartCount={StartCount}
              count={count}
              openTask={handleClickTaskPage}
              edit={HandleEditTask}
            />
          </div>
          <div className="container">
            <span className="header">Backlog</span>
            <NotesList
              notes={notes.filter((note) =>
                note.task_text
                  .toLowerCase()
                  .includes(
                    taskDue(cleanDate(note.due_date)) !== "today" &&
                      !note.done &&
                      searchText
                  )
              )}
              deleteNote={deleteNote}

              openTask={handleClickTaskPage}
              edit={HandleEditTask}
            />
          </div>
          <div className="container">
            <span className="header">Done</span>
            <NotesList
              notes={notes.filter((note) =>
                note.task_text.toLowerCase().includes(note.done && searchText)
              )}
              deleteNote={deleteNote}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AppDashboard;
