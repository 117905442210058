import React from "react";
import {MdSearch} from "react-icons/md"

const Search = ({ handleSearch }) => {
  return(
    <div className="search">
      <MdSearch className="task-search"/>
      <input onChange={(e)=>handleSearch(e.target.value)} type="text" placeholder="Search task"></input>
    </div>
  )
}

export default Search;