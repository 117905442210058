import React, { useEffect, useState} from "react";
import { useParams, useNavigate } from "react-router-dom";
import Timer from "../components/Timer";
import Task from "../components/Task";
import RetrieveTasks from "../api/RetrieveTasks";
import { createClient } from '@supabase/supabase-js'


const TaskPage = () => {

  const [note, setNote] = useState([])
  const [count, setCount] = useState(0);
  const [timerStatus, setTimerStatus] = useState("stop");
  const [isActive, setIsActive] = useState(false);
  const [seconds, setSeconds] = useState(null);
  const [startDayTime, setStartDayTime] = useState(null);
  const [currentDayTime, setCurrentDayTime] = useState(null);
  const [timeSinceStart, setTimeSinceStart] = useState(null);



  let navigate = useNavigate()
  let {id} = useParams()
  // console.log("params",id)

  const supabaseUrl = 'https://mpvrzftknsiwrkkttwag.supabase.co'
  const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im1wdnJ6ZnRrbnNpd3Jra3R0d2FnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODEzNDYyNDIsImV4cCI6MTk5NjkyMjI0Mn0.Nb6ZUFXaQ5MHMaqTT_kaiHcGwUM9OXkXeIv29qwqv2w'
  const supabase = createClient(supabaseUrl, supabaseAnonKey)

  const timeDifference = (start, end) => {
    // Calculate the time difference in milliseconds
    const timeDiff = end.getTime() - start.getTime();
  
    // Calculate hours, minutes, and seconds
    const hours = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
  
    // return {
    //   hours: hours,
    //   minutes: minutes,
    //   seconds: seconds
      
    // };
    return (timeDiff)
  }

  useEffect(() => {
    async function getData() {
      const { data, error } = await supabase.from("tasks").select("*").match({ id: id });
      if (error) {
        console.log(error);
      } else {
        setNote(data[0]);
      }
    }
    getData();
  }, []);


  useEffect(() => {
    if (timerStatus === "start") {
      const timer = setInterval(() => {
        setCurrentDayTime(new Date())
        setTimeSinceStart(timeDifference(startDayTime, currentDayTime)/100)
        setCount((count) => count + 1);

      }, 100);
      console.log("count", count)
      return () => clearInterval(timer);
    }
  }, [timerStatus, setCount, currentDayTime]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        setTimerStatus("stop");
        setIsActive(false);
        console.log('hidden')
      } else {
        setTimerStatus("start");
        setIsActive(true);
        console.log('visible')
      }
    }
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      // Cleanup when the component unmounts
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [isActive])



  const StartCount = () => {
    if (timerStatus === "stop") {
      if(!startDayTime){
        setStartDayTime(new Date())
      }
      setTimerStatus("start");
      setIsActive(true)
      console.log("timer status:",timerStatus, isActive)
    } else {
      setTimerStatus("stop");
      setIsActive(false)
      console.log("timer status:",timerStatus, isActive)
    }
  };


  const completeTask = async () => {
  const time = (Math.floor(count / 10))
  const filter = { id: note.id };
  const { data, error } = await supabase
  .from('tasks')
  .update({done:true, actual_time: time})
  .match(filter)
  if (error) {
    console.error(error);
  } else {
    navigate(`/todo`)
  }
  };
  

  return (
    <div className="task-page">
      <div className="task-container">
        <div className="timer-container">
          <div className="timer-icon"></div>
          {/* <div>Time since start: {timeSinceStart}</div> */}
          <Timer count={count} timeSinceStart={timeSinceStart} />
          <div className="timer-buttons">
            {timerStatus === "start" ? 
            <button className="save btn-timer" onClick={() => StartCount()}>Pause</button> :
            <button className="save btn-timer" onClick={() => StartCount()}>Start</button> 
            }
            <button className="save btn-timer" onClick={() => completeTask()}>Finish</button>
          </div>
        </div>
        <div className="text-container">
          <Task note={note}/>
        </div>
      </div>
    </div>
  );
};

export default TaskPage;
