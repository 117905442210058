import { useState } from "react";
import {
  MdDeleteForever,
  MdArrowRightAlt,
  MdDone,
  MdGrade,
  MdOutlinePlayCircleOutline,
  MdEdit,
} from "react-icons/md";
// import NotesList from "./NotesList";
import { cleanDate } from "../helpers";

const Note = ({
  note,
  deleteNote,
  // completeTask,
  addDays,
  StartCount,
  openTask,
  edit,
}) => {

  const [noteText, setNoteText] = useState(note.task_text);
  const [taskPriority, setTaskPriority] = useState(note.task_priority)
  const [dueDate, setDueDate] = useState(cleanDate(note.due_date))
  // const [timeEstimate, setTimeEstimate] = useState(0) 
  const [editTask, setEditTask] = useState(false);

  const EditTask = () => {
    setNoteText(note.task_text)
    setTaskPriority(note.task_priority)
    setDueDate(cleanDate(note.due_date))
    // console.log("note.task_text", note.task_text);
    // console.log("noteText", noteText)
    if (editTask === false) {
      setEditTask(true);
      // console.log("edit task? after", note.task_text);
      return;
    }
    setEditTask(false);
    // console.log("edit task? after", note.task_text);
    return;
  };

  const handleChange = (event) => {
    if(event.target.type === "textarea"){
      setNoteText(event.target.value)
    } else if (event.target.type=== "select-one") {
      setTaskPriority(event.target.value)
      console.log("task priority", taskPriority)
    } else {
      setDueDate(event.target.value)
    }
    // console.log(event.target.value)
  }  

  const pushEdits = () => {
    edit(note.id, dueDate, taskPriority, noteText)
    EditTask()
  }


  const priorityText = (priority) => {
    if (priority === 1) {
      return "High";
    } else if (priority === 2) {
      return "Medium";
    } else {
      return "Low";
    }
  };






  return (
    <div className={"note card " + (note.done ? "done" : "")}>
      {!editTask ? (
        <>
          <div className="note-header">
            <small>Priority: {note.task_priority}</small>
            <small>Due: {cleanDate(note.due_date)}</small>
          </div>
          <span className="note-text">{note.task_text}</span>
          {/* <div className="timer"></div> */}
          <div className="note-footer">
            {!note.done ? (
              <>
                <MdEdit onClick={EditTask} className="edit-note icon" />
                <MdOutlinePlayCircleOutline
                  onClick={() => openTask(note.id)}
                  className="start-note icon"
                />
                {/* <MdDone
              onClick={() => completeTask(note.id)}
              className="done-note icon"
              size="1.3em"
            /> */}
                <MdArrowRightAlt
                  onClick={() => addDays(note.due_date, note.id)}
                  className="push-note icon"
                  size="1.3em"
                />
                <MdDeleteForever
                  onClick={() => deleteNote(note.id)}
                  className="delete-note icon"
                  size="1.3em"
                />
              </>
            ) : (
              <>
                <MdDeleteForever
                  onClick={() => deleteNote(note.id)}
                  className="delete-note icon"
                  size="1.3em"
                />
                <MdGrade className="icon" />
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <div>
            <textarea
              type="text"
              id="text"
              name="text"
              rows="5"
              cols="10"
              onChange={handleChange}
              value={noteText}
            ></textarea>
            <div className="priority">
              <small>Priority:</small>
              <select
                name="priority"
                id="priority"
                className="dropdown"
                onChange={handleChange}
                value= {taskPriority}
              >
                <option valaue="4"></option>
                <option value="1">High</option>
                <option value="2">Medium</option>
                <option value="3">Low</option>
              </select>
            </div>
            <div>
              <small>Due Date: </small>
              <input
                className="date"
                type="date"
                id="due_date"
                name="due_date"
                onChange={handleChange}
                value={dueDate}
                min="2023-01-01"
              ></input>
            </div>
            <div className="note-footer">
            <button 
                className="cancel" 
                onClick={EditTask}
                >
                Cancel
              </button>
              <button 
                className="save" 
                onClick={pushEdits}
                >
                Save
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Note;
