import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AppDashboard from "./routes/AppDashboard";
import TaskPage from "./routes/TaskPage";
import HomePage from "./routes/HomePage";

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="todo" element={<AppDashboard />} />
          <Route exact path="todo/:id" element={<TaskPage />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
};

export default App;
