import React, { useState, useEffect } from "react";

const Timer = ({ count, timeSinceStart }) => {


  // console.log("count",count)
  let s = Math.floor((timeSinceStart / 10)%60).toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  let m = Math.floor(timeSinceStart / 600).toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  let h = Math.floor(m / 60).toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });


  return (
    <>
      <div className="timer">
        <div className="sticker"></div>

        <div className="time-box">
          <span>{h}</span>
          <small>hrs</small>
        </div>
        <span>:</span>
        <div className="time-box">
          <span>{m}</span>
          <small>min</small>
        </div>
        <span>:</span>
        <div className="time-box">
          <span>{s}</span>
          <small>sec</small>
        </div>
      </div>
    </>
  );
};

export default Timer;

