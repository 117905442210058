import React from "react";

const PageHeader = () => {

  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
  const d = new Date();
  let month = months[d.getMonth()];
  let day_number = d.getDate()
  let year = d.getFullYear()
  let day = days[d.getDay()]


  return(
    <div className="page-header">
      <span>Hello, </span><br></br>
      <span>Today is {day} {month} {day_number}, {year}</span>
    </div>
  )
}

export default PageHeader