import { useState } from "react";


const NewNote = ({ CreateNewNote }) => {

  const [noteText, setNoteText] = useState('');
  const [taskPriority, setTaskPriority] = useState('low')
  const [dueDate, setDueDate] = useState('')
  const [timeEstimate, setTimeEstimate] = useState(0) 

  const handleChange = (event) => {
    if(event.target.type === "textarea"){
      setNoteText(event.target.value)
    } else if (event.target.type=== "select-one") {
      setTaskPriority(event.target.value)
    } else if (event.target.type === "number"){
      setTimeEstimate(event.target.value)
    } else {
      setDueDate(event.target.value)
    }
    // console.log(event.target.value)
  }

  const handleSaveClick = () => {
    if(noteText.trim().length > 0){
      CreateNewNote(noteText, taskPriority, timeEstimate, dueDate)
      setTimeEstimate(0)
      setNoteText('')
      setDueDate('')
      setTaskPriority('')
    }
  }

  

  return (
    <div className="card new-note">
      <textarea
        type="text"
        id="text"
        name="text"
        placeholder="Create new task..."
        rows="5"
        cols="10"
        onChange={handleChange}
        value={noteText}
      ></textarea>
      <div className="priority">
      <small>Priority:</small><select name="priority" id="priority" className="dropdown" onChange={handleChange} value={taskPriority}>
        <option valaue="4"></option>
        <option value="1">High</option>
        <option value="2">Medium</option>
        <option value="3">Low</option>
      </select>
      </div>
      <div>
        <small>Time estimate: </small><input className="time-estimate" type="number" id="time" name="time" onChange={handleChange} value={timeEstimate}></input><small> minutes</small>
      </div>
      <div>
        <small>Due Date: </small><input className="date" type="date" id="due_date" name="due_date" onChange={handleChange} value={dueDate} min="2023-01-01"></input>
      </div>
      <div className="note-footer">
        <button className="save" onClick={handleSaveClick}>Save</button>
      </div>
    </div>
  );
};

export default NewNote;
