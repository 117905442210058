import Note from "./Note";

const NotesList = ({ notes, deleteNote, addDays, StartCount, openTask, edit }) => {
  return (
    <>
    <div className="notes-list scrollable">
      {notes.map((note) => (
        <Note note={note} deleteNote={deleteNote} addDays={addDays} StartCount={StartCount} openTask={openTask} edit={edit}/>
      ))}
    </div>
    </>
  );
};

export default NotesList;
