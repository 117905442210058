import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const HomePage = () => {
  return (
    <>
      <Helmet>
        <link rel="icon" type="image/png" href="%PUBLIC_URL%/astronaut.png" />
        <title>RPCB</title>
      </Helmet>
      <ul>
        <li>
          {/* <Link to="/todo">To-do</Link> */}
          <a href="https://glittery-speculoos-843bf1.netlify.app/">To-do</a>
        </li>
        <li>
          <a href="https://coruscating-wisp-b4d2bf.netlify.app/">Inpaint</a>
        </li>
      </ul>
    </>
  );
};

export default HomePage;
